<template>
    <AppFormCadastroNested
        ref="cadPeriodicidade"
        :service="service"
        :form="form"
        :disableSave="isInativo"
        :isNested="true"
        :origem="origem"
        :lastPage="lastPage"
        :backPath="`/procedure/${procedureId ?? 'list'}`"
        :personalBackPath="personalBackPath"
        @onBeforeSave="onBeforeSave"
        @onAfterSave="onAfterSave"
        @onLoadDataEdit="onLoadDataEdit"
        @onValidate="onValidate"
        @onHabilitarEdicao="habilitarEdicao"
        @onCancelarEdicao="cancelarEdicao"
    >
        <template #content>
            <div class="grid">
                <div class="col-3">
                    <label class="m-2 ml-0">Tipo da periodicidade</label>
                    <Dropdown
                        v-model="form.tipo"
                        :options="tiposPeriodicidade"
                        :optionDisabled="disableTipoPeriodicidadeMedicao"
                        optionValue="value"
                        optionLabel="label"
                        placeholder="Selecione..."
                        :disabled="isInativo"
                    />
                    <Message v-if="periodicidadeTipoMedicao && form.tipo" :closable="false">
                        <div class="flex flex-row">
                            <p class="mb-0 mr-4">Nível de ação: {{ risco?.nivelAcao ? risco.nivelAcao?.toFixed(2) : '-' }}</p>
                            <p class="mb-0 mr-2">Limite de tolerância: {{ risco?.limiteTolerancia ? risco.limiteTolerancia?.toFixed(2) : '-' }}</p>
                        </div>
                    </Message>
                </div>
            </div>

            <div class="grid">
                <div class="col-6" v-if="periodicidadeTipoPadrao && form.tipo">
                    <label for="periodicidadePadrao">Periodicidade padrão (meses)</label>
                    <InputNumber
                        inputId="periodicidadePadrao"
                        v-model="form.periodicidadePadrao"
                        autocomplete="off"
                        :class="{ 'p-invalid': submitted && validarPeriodicidadePadrao }"
                        :disabled="isInativo"
                    />
                    <small class="p-error" v-if="submitted && validarPeriodicidadePadrao">
                        {{ constsEnum.campoObrigatorio }}
                    </small>
                </div>

                <div class="col-3" v-if="periodicidadeTipoMedicao && form.tipo">
                    <label for="periodicidadeMedicaoInferiorNivelAcao">Quando inferior ao nível de ação (meses)</label>
                    <InputNumber
                        inputId="periodicidadeMedicaoInferiorNivelAcao"
                        v-model="form.periodicidadeMedicaoInferiorNivelAcao"
                        autocomplete="off"
                        :class="{ 'p-invalid': submitted && validarPeriodicidadeMedicaoInferiorNivelAcao }"
                        :disabled="isInativo"
                    />
                    <small class="p-error" v-if="submitted && validarPeriodicidadeMedicaoInferiorNivelAcao">
                        {{ constsEnum.campoObrigatorio }}
                    </small>
                </div>

                <div class="col-3" v-if="periodicidadeTipoMedicao && form.tipo">
                    <label for="periodicidadeMedicaoNivelAcaoLimiteTolerancia">Quando entre ao nível de ação e limite de tolerância (meses)</label>
                    <InputNumber
                        inputId="periodicidadeMedicaoNivelAcaoLimiteTolerancia"
                        v-model="form.periodicidadeMedicaoNivelAcaoLimiteTolerancia"
                        autocomplete="off"
                        :class="{ 'p-invalid': submitted && validarPeriodicidadeMedicaoNivelAcaoLimiteTolerancia }"
                        :disabled="isInativo"
                    />
                    <small class="p-error" v-if="submitted && validarPeriodicidadeMedicaoNivelAcaoLimiteTolerancia">
                        {{ constsEnum.campoObrigatorio }}
                    </small>
                </div>

                <div class="col-3" v-if="periodicidadeTipoMedicao && form.tipo">
                    <label for="periodicidadeMedicaoAcimaLimiteTolerancia">Quando acima do limite de tolerância (meses)</label>
                    <InputNumber
                        inputId="periodicidadeMedicaoAcimaLimiteTolerancia"
                        v-model="form.periodicidadeMedicaoAcimaLimiteTolerancia"
                        autocomplete="off"
                        :class="{ 'p-invalid': submitted && validarPeriodicidadeMedicaoAcimaLimiteTolerancia }"
                        :disabled="isInativo"
                    />
                    <small class="p-error" v-if="submitted && validarPeriodicidadeMedicaoAcimaLimiteTolerancia">
                        {{ constsEnum.campoObrigatorio }}
                    </small>
                </div>

                <div class="col-6" v-if="!periodicidadeTipoPadrao && !periodicidadeTipoMedicao && form.tipo">
                    <div class="grid">
                        <div class="col-6 align-items-center justify-content-between">
                            <label for="faixaIdade1">A partir de (anos) - faixa de idade 1</label>
                            <InputNumber
                                inputId="faixaIdade1"
                                v-model="form.faixaIdade1"
                                :class="{ 'p-invalid': submitted && !form.faixaIdade1 && !periodicidadeTipoPadrao }"
                                :disabled="isInativo"
                            />
                            <small class="p-error" v-if="submitted && !form.faixaIdade1 && !periodicidadeTipoPadrao">
                                {{ constsEnum.campoObrigatorio }}
                            </small>
                        </div>

                        <div class="col-6 align-items-center justify-content-between">
                            <label for="periodicidadeFaixaIdade1">Periodicidade faixa de idade 1 (meses)</label>
                            <InputNumber
                                inputId="periodicidadeFaixaIdade1"
                                v-model="form.periodicidadeFaixaIdade1"
                                :class="{ 'p-invalid': submitted && !form.periodicidadeFaixaIdade1 && !periodicidadeTipoPadrao }"
                                :disabled="isInativo"
                            />
                            <small class="p-error" v-if="submitted && !form.periodicidadeFaixaIdade1 && !periodicidadeTipoPadrao">
                                {{ constsEnum.campoObrigatorio }}
                            </small>
                        </div>
                    </div>
                </div>

                <div class="col-6" v-if="!periodicidadeTipoPadrao && !periodicidadeTipoMedicao && form.tipo && faixas[faixaCamposEnum.faixaIdade2]">
                    <div class="grid">
                        <div class="col-6 align-items-center justify-content-between">
                            <label for="faixaIdade2">A partir de (anos) - faixa de idade 2</label>
                            <InputNumber
                                inputId="faixaIdade2"
                                v-model="form.faixaIdade2"
                                :class="{ 'p-invalid': submitted && validaFaixaIdade2 }"
                                :disabled="isInativo"
                            />
                            <small class="p-error" v-if="submitted && validaFaixaIdade2">
                                {{ constsEnum.campoObrigatorio }}
                            </small>
                        </div>
                        <div class="col-6 align-items-center justify-content-between">
                            <label for="periodicidadeFaixaIdade2">Periodicidade faixa de idade 2 (meses)</label>
                            <InputNumber
                                inputId="periodicidadeFaixaIdade2"
                                v-model="form.periodicidadeFaixaIdade2"
                                :class="{ 'p-invalid': submitted && validaPeriodicidadeFaixaIdade2 }"
                                :disabled="isInativo"
                            />
                            <small class="p-error" v-if="submitted && validaPeriodicidadeFaixaIdade2">
                                {{ constsEnum.campoObrigatorio }}
                            </small>
                        </div>
                    </div>
                </div>

                <div class="col-6" v-if="!periodicidadeTipoPadrao && !periodicidadeTipoMedicao && form.tipo && faixas[faixaCamposEnum.faixaIdade3]">
                    <div class="grid">
                        <div class="col-6 align-items-center justify-content-between">
                            <label for="faixaIdade3">A partir de (anos) - faixa de idade 3</label>
                            <InputNumber
                                inputId="faixaIdade3"
                                v-model="form.faixaIdade3"
                                :class="{ 'p-invalid': submitted && validaFaixaIdade3 }"
                                :disabled="isInativo"
                            />
                            <small class="p-error" v-if="submitted && validaFaixaIdade3">
                                {{ constsEnum.campoObrigatorio }}
                            </small>
                        </div>
                        <div class="col-6 align-items-center justify-content-between">
                            <label for="periodicidadeFaixaIdade3">Periodicidade faixa de idade 3 (meses)</label>
                            <InputNumber
                                inputId="periodicidadeFaixaIdade3"
                                v-model="form.periodicidadeFaixaIdade3"
                                :class="{ 'p-invalid': submitted && validaPeriodicidadeFaixaIdade3 }"
                                :disabled="isInativo"
                            />
                            <small class="p-error" v-if="submitted && validaPeriodicidadeFaixaIdade3">
                                {{ constsEnum.campoObrigatorio }}
                            </small>
                        </div>
                    </div>
                </div>

                <div class="col-6" v-if="!periodicidadeTipoPadrao && !periodicidadeTipoMedicao && form.tipo && faixas[faixaCamposEnum.faixaIdade4]">
                    <div class="grid">
                        <div class="col-6 align-items-center justify-content-between">
                            <label for="faixaIdade4">A partir de (anos) - faixa de idade 4</label>
                            <InputNumber
                                inputId="faixaIdade4"
                                v-model="form.faixaIdade4"
                                :class="{ 'p-invalid': submitted && validaFaixaIdade4 }"
                                :disabled="isInativo"
                            />
                            <small class="p-error" v-if="submitted && validaFaixaIdade4">
                                {{ constsEnum.campoObrigatorio }}
                            </small>
                        </div>
                        <div class="col-6 align-items-center justify-content-between">
                            <label for="periodicidadeFaixaIdade4">Periodicidade faixa de idade 4 (meses)</label>
                            <InputNumber
                                inputId="periodicidadeFaixaIdade4"
                                v-model="form.periodicidadeFaixaIdade4"
                                :class="{ 'p-invalid': submitted && validaPeriodicidadeFaixaIdade4 }"
                                :disabled="isInativo"
                            />
                            <small class="p-error" v-if="submitted && validaPeriodicidadeFaixaIdade4">
                                {{ constsEnum.campoObrigatorio }}
                            </small>
                        </div>
                    </div>
                </div>
            </div>

            <div class="grid" v-if="!periodicidadeTipoPadrao && !periodicidadeTipoMedicao && form.tipo">
                <div class="col-3">
                    <Button @click="adicionarFaixa" label="Adicionar faixa" :disabled="isInativo" />
                </div>
                <div class="col-3">
                    <Button
                        label="Remover faixa"
                        icon="pi pi-times"
                        class="p-button-danger p-button-outlined"
                        @click="removerFaixa"
                        :disabled="isInativo"
                    />
                </div>
            </div>

            <div class="grid pt-3">
                <div class="col-6">
                    <label for="periodicidadeDoencaCronica">Periodicidade por doença crônica (meses)</label>
                    <InputNumber inputId="periodicidadeDoencaCronica" v-model="form.periodicidadeDoencaCronica" :disabled="isInativo" />
                </div>
                <div class="col-6">
                    <label for="periodicidadeAdmissao">Periodicidade após admissão (meses)</label>
                    <InputNumber inputId="periodicidadeAdmissao" v-model="form.periodicidadeAdmissao" :disabled="isInativo" />
                </div>
            </div>
        </template>
    </AppFormCadastroNested>
</template>

<script>
import NestedService from '../../services/NestedService';
import FaixaCamposEnum from '../../enums/FaixaCamposEnum';
import TipoPeriodicidadeEnum, { TipoPeriodicidadeLabelEnum } from '../../enums/TipoPeriodicidadeEnum';
import ConstsEnum from '../../enums/ConstsEnum';
import TipoAvaliacaoRisco from '../../enums/TipoAvaliacaoRisco';

export default {
    props: {
        active: Number,
        origem: String,
        lastPage: {
            type: Boolean,
            default: false
        },
        personalBackPath: {
            type: String,
            default: null
        },
        risco: {
            type: Object
        },
        procedureId: {
            type: String,
            default: null
        }
    },
    emits: ['desabilitarAbas', 'habilitarAbas', 'setPeriodicidadeRisco'],
    data() {
        return {
            form: {},
            isInativo: false,
            service: null,
            submitted: false,
            faixas: [],
            teste: TipoPeriodicidadeEnum,
            tiposPeriodicidade: [
                {
                    value: TipoPeriodicidadeEnum.NONE,
                    label: TipoPeriodicidadeLabelEnum.get(TipoPeriodicidadeEnum.NONE)
                },
                {
                    value: TipoPeriodicidadeEnum.PADRAO,
                    label: TipoPeriodicidadeLabelEnum.get(TipoPeriodicidadeEnum.PADRAO)
                },
                {
                    value: TipoPeriodicidadeEnum.IDADE,
                    label: TipoPeriodicidadeLabelEnum.get(TipoPeriodicidadeEnum.IDADE)
                },
                {
                    value: TipoPeriodicidadeEnum.MEDICAO,
                    label: TipoPeriodicidadeLabelEnum.get(TipoPeriodicidadeEnum.MEDICAO)
                }
            ]
        };
    },
    created() {
        this.service = new NestedService('/periodicidades');
    },
    mounted() {
        this.isInativo = !!this.$route.params.id;
        this.form.ativo = true;
    },
    computed: {
        constsEnum() {
            return ConstsEnum;
        },
        periodicidadeTipoPadrao() {
            return this.form.tipo == TipoPeriodicidadeEnum.PADRAO;
        },
        validarPeriodicidadePadrao() {
            return !this.form.periodicidadePadrao && this.periodicidadeTipoPadrao;
        },
        faixaCamposEnum() {
            return FaixaCamposEnum;
        },
        validaFaixaIdade2() {
            return !this.form.faixaIdade2 && this.faixas[FaixaCamposEnum.faixaIdade2] && !this.periodicidadeTipoPadrao;
        },
        validaFaixaIdade3() {
            return !this.form.faixaIdade3 && this.faixas[FaixaCamposEnum.faixaIdade3] && !this.periodicidadeTipoPadrao;
        },
        validaFaixaIdade4() {
            return !this.form.faixaIdade4 && this.faixas[FaixaCamposEnum.faixaIdade4] && !this.periodicidadeTipoPadrao;
        },
        validaPeriodicidadeFaixaIdade2() {
            return !this.form.periodicidadeFaixaIdade2 && this.faixas[FaixaCamposEnum.faixaIdade2] && !this.periodicidadeTipoPadrao;
        },
        validaPeriodicidadeFaixaIdade3() {
            return !this.form.periodicidadeFaixaIdade3 && this.faixas[FaixaCamposEnum.faixaIdade3] && !this.periodicidadeTipoPadrao;
        },
        validaPeriodicidadeFaixaIdade4() {
            return !this.form.periodicidadeFaixaIdade4 && this.faixas[FaixaCamposEnum.faixaIdade4] && !this.periodicidadeTipoPadrao;
        },
        periodicidadeTipoMedicao() {
            return this.form.tipo == TipoPeriodicidadeEnum.MEDICAO;
        },
        validarPeriodicidadeMedicaoInferiorNivelAcao() {
            return !this.form.periodicidadeMedicaoInferiorNivelAcao && this.periodicidadeTipoMedicao;
        },
        validarPeriodicidadeMedicaoNivelAcaoLimiteTolerancia() {
            return !this.form.periodicidadeMedicaoNivelAcaoLimiteTolerancia && this.periodicidadeTipoMedicao;
        },
        validarPeriodicidadeMedicaoAcimaLimiteTolerancia() {
            return !this.form.periodicidadeMedicaoAcimaLimiteTolerancia && this.periodicidadeTipoMedicao;
        }
    },
    methods: {
        disableTipoPeriodicidadeMedicao(option) {
            return (
                (!this.$route.path.includes('risco-procedimento') || this.risco?.tipoAvaliacao !== TipoAvaliacaoRisco.CRITERIO_QUANTITATIVO) &&
                option.value === TipoPeriodicidadeEnum.MEDICAO
            );
        },
        carregaFaixas() {
            if (this.form.faixaIdade2) this.faixas.push('Idade2');
            if (this.form.faixaIdade3) this.faixas.push('Idade3');
            if (this.form.faixaIdade4) this.faixas.push('Idade4');
        },
        adicionarFaixa() {
            if (this.faixas.length < Object.keys(FaixaCamposEnum).length) this.faixas.push('itemAdd');
        },
        removerFaixa() {
            this.form['faixa' + this.faixas[this.faixas.length - 1]] = null;
            this.form['periodicidadeFaixa' + this.faixas[this.faixas.length - 1]] = null;
            this.faixas.pop();
        },
        async onLoadDataEdit(data) {
            this.form = data;
            this.carregaFaixas();
            this.$emit('setPeriodicidadeRisco', data);
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                this.$toast.add({ severity: 'error', summary: 'Há campos obrigatórios não preenchidos', life: 3000 });
                return cbError();
            }

            return cbSuccess();
        },
        hasError() {
            if (this.validarPeriodicidadePadrao) return true;
            if (this.validarPeriodicidadeMedicaoInferiorNivelAcao) return true;
            if (this.validarPeriodicidadeMedicaoNivelAcaoLimiteTolerancia) return true;
            if (this.validarPeriodicidadeMedicaoAcimaLimiteTolerancia) return true;
            if (this.validaFaixaIdade2 && this.validaPeriodicidadeFaixaIdade2) return true;
            if (this.validaFaixaIdade3 && this.validaPeriodicidadeFaixaIdade3) return true;
            if (this.validaFaixaIdade3 && this.validaPeriodicidadeFaixaIdade4) return true;
        },
        async onBeforeSave() {
            this.form.idOrigem = this.$route.params.id;
            this.form.origem = this.origem;
            this.submitted = true;
        },
        async onAfterSave(response) {
            this.isInativo = true;
            this.submitted = false;
            this.form = response.data;
            this.$emit('habilitarAbas');
            this.$emit('setPeriodicidadeRisco', response.data);
        },
        habilitarEdicao() {
            this.$emit('desabilitarAbas');
            this.isInativo = false;
        },
        cancelarEdicao(record) {
            this.isInativo = true;
            this.form = JSON.parse(JSON.stringify(record));
            this.$emit('habilitarAbas');
        }
    }
};
</script>
